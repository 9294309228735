import React from 'react'
import { Building, House } from '@api/endpoints/buildings/houses'
import { LayoutTypeView } from '@api/endpoints/buildings/layoutTypes'

type HouseFormContext = {
  house: House
  building?: Building
  layoutType?: LayoutTypeView
}

export const HouseFormContext = React.createContext({} as HouseFormContext)

export const useHouseFormContext = (): HouseFormContext => React.useContext(HouseFormContext)
